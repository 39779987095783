/**
 * Получает информацию о бронировании для определенного отеля по идентификатору бронированияиз Яндекс Функций (перенесе на Яндекс Функцию дабы разгрузить Urlfetch)
 *
 * @param {string} hotelName - Название отеля.
 * @param {string} bookingID - Идентификатор бронирования.
 * @returns {Promise<Object>} Промис с информацией о бронировании.
 */
async function fetchBooking(hotelName, bookingID) {
    return fetchYandexByMethod('fetchBooking', {hotelName, bookingID});
}

/**
 * Получает список операций из Яндекс Функции (так как гугл не поддерживает передачу массива с данными из бд в формате JSON по какой-то причине)
 *
 * @param {string} userUid - Идентификатор бронирования.
 * @returns {Promise<Array>} Промис с информацией о бронировании.
 */
async function fetchUserOperations(userUid) {
    return fetchYandexByMethod('fetchUserOperations', {userUid});
}

/**
 * Удаляет операцию из списка
 *
 * @param {string} userUid - Идентификатор бронирования.
 * @param {string} operationId - Идентификатор операции.
 * @returns {Promise<Array>} Промис с информацией о бронировании.
 */
async function deleteUserOperation(userUid, operationId) {
    return fetchYandexByMethod('deleteUserOperation', {userUid, operationId});
}

/**
 * Сохраняет дополнительную услугу
 *
 * @async
 * @param {number} id - Идентификатор услуги.
 * @param {string} hotelName - Название отеля.
 * @param {Object} additionalServiceData - Данные дополнительной услуги.
 * @returns {Promise<Object>} - Объект с результатом выполнения функции.
 */
async function saveAdditionalService(id, hotelName, additionalServiceData) {
    return fetchByMethod('saveAdditionalService', 'POST', {
        id,
        hotelName,
        additionalServiceData
    });
}

/**
 * Добавляет информацию об оплате в систему.
 *
 * @param {string} paymentType - Тип оплаты (deposit/accommodation/additional-service).
 * @param paidAmount
 * @param {string} bnovoAccount - Учетная запись Bnovo.
 * @param {string} ddsAccount - Учетная запись DDS.
 * @param {string} bookingId - Идентификатор бронирования.
 * @param {string} id - Идентификатор брони pageId.
 * @param {string} hotelName - Краткое название отеля.
 * @param {string} guestName - Имя гостя.
 * @param {string} userEmail - Email пользователя (создавшего операцию)
 * @param {string} userUid - Uid пользователя (создавшего операцию)
 * @param {string} paymentDate - Дата оплаты.
 * @param {string} paymentCreator - Создатель записи об оплате.
 * @param {Object | undefined} additionalServiceData - Данные об доп услуге (если запрос на добавление оплаты за доп.услугу).
 * @returns {Promise<Object>} Промис с результатом добавления оплаты.
 */
async function addPayment(paymentType, paidAmount, bnovoAccount, ddsAccount, bookingId, id, hotelName, guestName, userEmail, userUid, paymentDate, paymentCreator, additionalServiceData) {
    return fetchByMethod("addPayment", "POST", {
        operationType: "income", // Добавлено значение по умолчанию, можно изменить по необходимости
        bookingIntNumber: id,
        bookingPublicNumber: bookingId,
        paymentType,
        guestName,
        userEmail,
        userUid,
        hotelName,
        paymentDate,
        paymentSourceName: ddsAccount,
        paymentCreatorName: paymentCreator,
        paidAmount,
        bnovoAccount,
        comment: `Бронирование ${bookingId}: Внесение через форму ${paymentCreator}`, // Пример комментария, можно изменить
        additionalServiceData
    });
}

/**
 * Отправляет запрос на добавление строчки с возратом в ДДС через Google App Script
 *
 * @param {string} hotelName - Имя отеля (из fetchBooking)
 * @param {string} userEmail - Email пользователя кто создал операцию
 * @param {string} userUid - Uid пользователя кто создал операцию
 * @param {string} paymentType - Тип платежа (accommodation/deposit/additional-service)
 * @param {string} paymentCreatorName - Имя создателя платежа.
 * @param {string} paymentSourceName - Название источника платежа.
 * @param {number | string} amount - Сумма платежа.
 * @param {string} bookingId - ID бронирования (публичный).
 * @param {string} bookingPageId - ID страницы бронирования.
 * @param {string} arrivalDateString - Дата прибытия в формате строки.
 * @param {string} departureDateString - Дата отъезда в формате строки.
 * @param {string} operationId - Сгенерированный уникальный ID операции.
 * @param {string} paidDateString - Дата оплаты в формате строки.
 * @param {string} roomName - Название комнаты.
 * @param {string} paymentWay - Способ оплаты.
 * @param {string} guestName - Имя человека, совершившего платеж.
 * @param {string} comment - Комментарий, который вставится над именем контрагента в ДДС
 * @param {string} cancelReason - Из поля причина отмены
 * @param {string} hotelId - Id отеля
 * @returns {Promise<Object>} Промис с результатом добавления информации о возврате платежа.
 */
async function addRefundPayment(
    hotelName,
    userEmail,
    userUid,
    paymentType,
    paymentCreatorName,
    paymentSourceName,
    amount,
    bookingId,
    bookingPageId,
    arrivalDateString,
    departureDateString,
    operationId,
    paidDateString,
    roomName,
    paymentWay,
    guestName,
    comment,
    cancelReason,
    hotelId,
) {
    return fetchByMethod('addRefundPayment', 'POST', {
        hotelName,
        userEmail,
        userUid,
        paymentType,
        scriptId: config.addRefundAxisFinanceScriptId,
        paymentCreatorName,
        paymentSourceName,
        amount,
        bookingId,
        bookingPageId,
        arrivalDateString,
        departureDateString,
        operationId,
        paidDateString,
        roomName,
        paymentWay,
        guestName,
        comment,
        cancelReason,
        hotelId,
    });
}

/**
 * Создает платеж и обновляет информацию о платеже в базе данных.
 *
 * @async
 * @param {string} operationId - Id операции
 * @param {string} operationType - Тип операции (как в ддс: income/refund)
 * @param {string} bookingIntNumber - Уникальный идентификатор bookingPageId.
 * @param {string} bookingPublicNumber - Публичный номер бронирования bookingPublicNumber.
 * @param {string} paymentType - Тип платежа: accommodation, deposit, additional-service
 * @param {string} guestName - Имя гостя.
 * @param {string} userEmail - Email пользователя кто создал операцию
 * @param {string} userUid - Uid пользователя кто создал операцию
 * @param {string} hotelName - Название отеля.
 * @param {Object} paymentDate - Объект с информацией о дате платежа.
 * @param {Object} paymentSourceName - Объект с информацией о дате платежа.
 * @param {Object} paymentCreatorName - Объект с информацией о дате платежа.
 * @param {number} paidAmount - Сумма платежа.
 * @param {string} bnovoAccount - Аккаунт в системе bnovo.
 * @param {string} comment - Комментарий к платежу.
 * @param {string} cancelReason - Причина отмены бронирования.
 * @param {string} paymentWay - Способ оплаты (онлайн-оплата/безналичная оплата/оплата по ссылке и тд просто из формы select)
 * @returns {Promise<Object>} Возвращает ответ от сервера.
 * @throws {Error} Бросает ошибку, если произошла проблема во время выполнения запроса.
 */
async function createInProgressRefundOperation(
  operationId,
  operationType,
  bookingIntNumber,
  bookingPublicNumber,
  paymentType,
  guestName,
  userEmail,
  userUid,
  hotelName,
  paymentDate,
  paymentSourceName,
  paymentCreatorName,
  paidAmount,
  bnovoAccount,
  comment,
  cancelReason,
  paymentWay,
) {
  return fetchByMethod('createInProgressRefundOperation', 'POST', {
    bodyArgs: {
      operationId,
      operationType,
      bookingIntNumber,
      bookingPublicNumber,
      paymentType,
      guestName,
      userEmail,
      userUid,
      hotelName,
      paymentDate,
      paymentSourceName,
      paymentCreatorName,
      paidAmount,
      bnovoAccount,
      comment,
      cancelReason,
      paymentWay,
    },
    url: config.yandexScriptUrl,
    queryArgs: {
      method: 'addRefundOperation',
    },
    method: 'POST'
  });
}

/**
 * Получает список учетных записей.
 *
 * @returns {Promise<Array>} Промис со списком учетных записей.
 */
async function fetchAccounts() {
    return fetchByMethod('fetchAccounts', 'GET');
}


/**
 * Сохраняет файлы в указанной папке для конкретного отеля.
 *
 * @param {Array<File>} files - Список файлов для сохранения.
 * @param {string} hotelName - Название отеля.
 * @param {string} folderKey - Ключ папки, в которой следует сохранить файлы.
 * @returns {Promise<Object>} Промис с результатом сохранения файлов.
 */
async function saveFiles(files, hotelName, folderKey) {
    return fetchByMethod('saveFiles', 'POST', {files, hotelName, folderKey});
}

